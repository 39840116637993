import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const TabTest = (props) => (
    <Layout>
        <Helmet>
            <title>Tabs test</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Tabs</h1>
                    </header>
                    <Tabs>
                        <TabList>
                            <Tab>Title 1</Tab>
                            <Tab>Title 2</Tab>
                        </TabList>
                        
                        <TabPanel>
                            <h2>Any content 1</h2>
                        </TabPanel>
                        <TabPanel>
                            <h2>Any content 2</h2>
                        </TabPanel>
                    </Tabs>
                </div>
            </section>
        </div>

    </Layout>
)

export default TabTest